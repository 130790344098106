@import "open-props/open-props.min.css";
@import "open-props/media.min.css";
@import "open-props/normalize.light.min.css";
@import "container.css";
@import "utilities.css";
@import "@shoelace-style/shoelace/dist/themes/light.css";

:root{
  --font-normal: var(--font-weight-4);
  --font-bold: var(--font-weight-6);
  --text-sm: var(--font-size-1);
  --text-md: var(--font-size-2);
  --text-lg: var(--font-size-3);
  --link: var(--brand);
  --link-visited: var(--brand);
  --brand: var(--teal-8);
}

html{
  scroll-behavior: auto;
}


body {
  color: var(--text-color-normal);
  font-size: var(--text-md);
  font-family: var(--font-sans);
}

h1{
  font-size: var(--font-size-fluid-3);
}

h2{
  font-size: var(--font-size-fluid-2);
}

h3{
  font-size: var(--font-size-fluid-1);
}

p{
  padding-block: var(--size-3);
}

strong{
  font-weight: var(--font-bold);
}

ol + h2{
  margin-block-start: var(--size-4);
}

a, a:visited {
  user-select: none;
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);

  margin-block: 0;
  margin-inline: 0;
  padding-block: 0;
  padding-inline: 0;

  &:hover{
    text-decoration-color: var(--brand);
  }
}

pre{
  max-width: none;
  font-size: var(--font-size-1);
}


center-l p img{
  margin-inline: auto;
  box-shadow: var(--shadow-4);
}

sl-card::part(base){
  border-radius: var(--radius-3);
}

.card{
  box-shadow: var(--shadow-3);
  padding: var(--size-6);
  background: white;
  border-radius: var(--radius-3);
  border: var(--border-size-2) solid var(--teal-1);

  
  &:hover{
    box-shadow: var(--shadow-4);
  }
}

