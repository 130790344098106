.container{
  margin-inline: auto;
  padding-inline: var(--size-fluid-1);

  @media(--sm-n-above){
    max-width: var(--size-sm);
  }

  @media(--md-n-above){
    max-width: var(--size-md);
  }

  @media(--lg-n-above){
    max-width: var(--size-lg);
  }
  
  @media(--xl-n-above){
    max-width: var(--size-xl);
  }

  @media(--xxl-n-above){
    max-width: var(--size-xxl);
  }
}

